import React, { useEffect, useRef, useState } from 'react';

export default function Gallery(props) {
  const galleryRef = useRef();
  const [index, setIndex] = useState();
  const showOverlay = !isNaN(index);

  // handles selecting an image
  function onSelectImage(newIndex) {
    setIndex(newIndex);
  }

  function cancelEvent(event) {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  }

  // moves to the next item
  function onNext(event) {
    setIndex(index + 1);
    cancelEvent(event);
  }
  
  // moves to the previous item
  function onPrevious(event) {
    setIndex(index - 1);
    cancelEvent(event);
  }
  
  // closes the view
  function onClose() {
    setIndex(undefined);
  }

  function renderContent(item) {
    const img = <img className="gallery-overlay--current" src={item.src} onPointerDown={onNext} />;

    if (item.video) {
      return <div className="gallery-overlay--current" onPointerDown={onNext} >
        <video controls poster={item.src}>
          {['mp4', 'mov', 'oog'].map(type => {
            const path = item[type];
            return path && <source key={`video_${type}`} src={path} type={`video/${type}`} />
          })}

          {img}
        </video>
      </div>
    }
    else {
      return img;
    }
  }

  // displays the showcase view
  function renderOverlay() {
    const total = props.images.length;
    const current = props.images[Math.abs(index % total)];

    // display the view
    return <div className="gallery-overlay" onPointerDown={onClose} >
      <div className="gallery-overlay--close" onPointerDown={onClose} >&times;</div>
      <div className="gallery-overlay--go-previous" onPointerDown={onPrevious} >&larr;</div>
      <div className="gallery-overlay--go-next" onPointerDown={onNext} >&rarr;</div>
      {renderContent(current)}
    </div>
  }

  // displays an individual item
  function renderItem(img, position) {
    return (
      <div className="gallery-item" key={img.src} onClick={() => onSelectImage(position)} >
        <div className="gallery-item--about" >
          <div className="gallery-item--about--title" >{img.name}</div>
          <div className="gallery-item--about--section" >{img.category.name}</div>
          {img.for && (
            <div className="gallery-item--about--for" title={img.for.desc} >
              {img.for.name}
            </div>
          )}
        </div>

        {img.video && <div className="gallery-item--video" >Video</div>}
        <img src={img.src} />
      </div>
    );
  }

  // displays the entire gallery
  function renderGallery() {
    return props.images.map(renderItem);
  }

  // tries to make a mason effect when loading
  useEffect(() => masonify(galleryRef.current), []);

  return (
    <>
      {showOverlay && renderOverlay()}
      <div className="gallery" ref={galleryRef} >
        {renderGallery()}
      </div>
    </>
  )

}

// make a mason layout, as possible
function masonify(el) {
  el.className += ' gallery-masonry';

  // create the containers
  const columns = [ ];

  for (let i = 0; i < 3; i++) {
    const container = document.createElement('div');
    container.className = 'gallery-columns';
    el.appendChild(container);
    columns.push({ el: container, height: 0 });
  }

  function smallest() {
    let keep = columns[0];
    for (const col of columns) {
      if (col.height <= keep.height) {
        keep = col
      }
    }
    return keep;
  }

  // measure
  const imgs = el.querySelectorAll('.gallery-item');
  for (const img of imgs) {
    const p = img.querySelector('img');
    const fit = () => {
      const height = p.height;
      const target = smallest();
      target.height += height;
      target.el.appendChild(img);
      p.className = '';
    };

    p.className = 'is-loading';

    p.onload = fit;
    if (!isNaN(p.height)) {
      fit();
    }

  }

}