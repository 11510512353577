import React from 'react';
import classnames from 'classnames';

const LINKS = [
  { url: '/', label: 'Home' },
  { url: '/design', label: 'Design' },
  { url: '/illustration', label: 'Illustration' },
  { url: '/other', label: 'Other' },
  { url: 'https://www.visualcv.com/gwynevere-rose/', label: 'Resume', target: '_blank' },
  { url: 'mailto:bonaccigwyn18@gmail.com', label: 'Contact' },
];

export default function Header(props) {
  const min = 0;
  const max = LINKS.length;
  const mid = max / 2;

  function renderLinks(start, end) {
    const links = [ ];

    for (let i = start; i < end; i++) {
      const link = LINKS[i];
      const linkCx = classnames({
        active: props.path === link.url
      });

      const target = link.target ? { target: link.target } : { }

      links.push(
        <li key={link.url} className={linkCx} >
          <a href={link.url} { ...target } >{link.label}</a>
        </li>
      );
    }

    return links;
  }
  
  return (
    <header>
      <nav>
        <div className="logo">
          <a href="/" >
            GB
          </a>
        </div>
        <ul className="links left">
          {renderLinks(min, mid)}
        </ul>
        <ul className="links right">
          {renderLinks(mid, max)}
        </ul>
      </nav>
    </header>
  );
}
