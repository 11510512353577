import * as React from 'react';
import Header from '../components/Header';
import '../styles/index.scss';
import Footer from './Footer';

export default function Layout(props) {

  return (
    <div>
      <title>{props.title}</title>

      <Header path={props.path} />

      {props.header && (
        <div className="section--header" >{props.header}</div>
      )}

      <div>
        {props.children}
      </div>

      <Footer />
    </div>
  );
}
