import portfolio from './portfolio.yml';

// gather all 
function importAll(r) {
  const imported = [ ];
  for (const key of r.keys()) {
    const { default: path } = r(key);
    imported.push({ key, path })
  }

  return imported;
}

// load all images
const content = importAll(require.context('./', false, /\.(png|jpe?g|svg)$/));

// map everything
for (const id in portfolio.images) {
  const ref = portfolio.images[id];
  const img = content.find(match => match.key === `./${id}.jpg`);
  ref.src = img.path;
  ref.for = portfolio.sources[ref.for];
  ref.category = portfolio.categories[ref.category];

  // if is a video
  if (ref.video) {
    for (const type of ['mp4', 'mov', 'oog']) {
      ref[type] = `/videos/${id}.${type}`;
    }
  }
}

// map the views
for (const id in portfolio.views) {
  portfolio.views[id] = portfolio.views[id].map(key => portfolio.images[key]);
}

export default portfolio;
