import React from 'react';

export default function Footer() {

  return (
    <div className="footer" >
      &copy; {(new Date()).getFullYear()} Gwynevere Rose &hearts; All rights reserved
    </div>
  );

}